<template>
  <div id="layout-wrapper">
    <gouv-header />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <main id="main-content" role="main" :dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'">
      <div id="#top" v-b-visible.25="visibleHandler"></div>
      <nuxt />
    </main>
    <gouv-footer v-if="!$config.tauri" />
    <consent-banner v-if="!$config.tauri" />
    <structured-data-corporation v-if="!$config.tauri" />
    <structured-data-organisation v-if="!$config.tauri" />
    <structured-data-website v-if="!$config.tauri" />
    <structured-data-graph v-if="!$config.tauri" />
  </div>
</template>

<script>
import StructuredDataCorporation from '~/components/seo/structured-data-corporations';
import StructuredDataOrganisation from '~/components/seo/structured-data-organization';
import StructuredDataWebsite from '~/components/seo/structured-data-website';
import StructuredDataGraph from '~/components/seo/structured-data-graph';
import ConsentBanner from '~/components/common/cookies/consent-banner';
import GouvHeader from '~/components/ui/gouv/gouv-header';
import GouvFooter from '~/components/site/gouv/gouv-footer';
import { getGouvDefaultMetaTags } from '~/helpers';

export default {
  name: 'LayoutTroovSite',
  components: {
    GouvFooter,
    GouvHeader,
    ConsentBanner,
    StructuredDataGraph,
    StructuredDataWebsite,
    StructuredDataOrganisation,
    StructuredDataCorporation
  },
  data() {
    return {
      isLeftBarEnabled: false,
      hasScrollDown: false
    };
  },
  head() {
    // disabled temporary for the FAQ
    const dnsPrefetch = [
      { rel: 'dns-prefetch', href: this.host },
      { rel: 'preconnect', href: this.host, crossorigin: true }
    ];
    const link = {
      link: [
        ...dnsPrefetch,
        { hid: 'dsfr-css', rel: 'stylesheet', href: '/dsfr/styles/dsfr.min.css' },
        { hid: 'dsfr-css-util', rel: 'stylesheet', href: '/dsfr/styles/utility/utility.min.css' }
      ]
    };
    const defaultMeta = getGouvDefaultMetaTags(this.host);

    return {
      htmlAttrs: {
        lang: this.locale
      },
      meta: [...defaultMeta, { hid: 'language', name: 'language', content: this.$i18n.locale.toUpperCase() }],
      bodyAttrs: {
        'data-layout': 'horizontal',
        'data-topbar': 'light',
        'data-topbar-mode': 'simple',
        'data-layout-form': this.theme,
        class: this.bodyClass
      },
      script: [
        { hid: 'dsfr-js-config', src: '/dsfr/styles/dsfr.config.js', body: true },
        { hid: 'dsfr-js', src: '/dsfr/styles/dsfr.nomodule.min.js', nomodule: true, body: true },
        { hid: 'dsfr-js-module', type: 'module', src: '/dsfr/styles/dsfr.module.min.js', body: true }
      ],
      ...link
    };
  },
  computed: {
    host() {
      return this.$config.HOST_NUXT;
    },
    locale() {
      return this.$i18n.locale;
    },
    bgClass() {
      return {
        'bg-troov-blue-sky': true
      };
    },
    isGouvLayout() {
      return true;
    },
    bodyClass() {
      let cssClasses = '';
      if (this.isLeftBarEnabled) {
        cssClasses = 'left-bar-enabled';
      }
      return cssClasses;
    },
    currentRoute() {
      return this.getRouteBaseName();
    },
    theme() {
      return this.useGouvColor || this.isGouvLayout ? 'ttc' : 'troov';
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
    dynamicHreflang() {
      return this.availableLocales.map((lang) => {
        return {
          hid: `hreflang_${lang.iso}`,
          lang: lang.code,
          hreflang: lang.code,
          rel: 'alternate',
          href: `${this.host}${this.switchLocalePath(lang.code)}`
        };
      });
    }
  },
  watch: {
    locale(oldValue, newValue) {
      this.$dayjs.locale(this.$i18n.locale);
    }
  },
  mounted() {
    const iframeMode = !!this.$router.currentRoute.query.iframe;

    this.$dayjs.locale(this.$i18n.locale);
    if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
      // detect it's IE11
      window.addEventListener(
        'hashchange',
        (event) => {
          const currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
    if (window.top !== window.self && !iframeMode) {
      window.top.location.replace(window.self.location.href);
    }
  },
  methods: {
    visibleHandler(isVisible) {
      this.hasScrollDown = !isVisible;
    }
  }
};
</script>
